import { Box, Card, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import JSConfetti from 'js-confetti'

type Props = {
  artistName: string
}

export const SuccessComponent = ({ artistName }: Props) => {
  useEffect(() => {
    const jsConfetti = new JSConfetti()
    jsConfetti.addConfetti({ confettiColors: ['white', 'gray'] })
  }, [])

  return (
    <Card>
      <Stack alignItems="center">
        <Typography variant="h3">Success</Typography>
        <Box
          component="img"
          sx={{
            maxHeight: { lg: 297, md: 233, xs: 167 },
            maxWidth: { lg: 450, md: 350, xs: 250 },
          }}
          alt="Success"
          src={'/skull.png'}
        />
        <Typography variant="h5">{`Your booking request was send to ${artistName}`}!</Typography>
        <Typography>You can safely close your browser window now.</Typography>
      </Stack>
    </Card>
  )
}
