import React from 'react'
// eslint-disable-next-line no-restricted-imports -- Use sx props or styled components to create CSS
import { Stack, useTheme } from '@mui/material'
import { FileRejection } from 'react-dropzone'

import { FileListText } from 'components/upload/FileListText'

type Props = {
  files: FileRejection[]
}

export const RejectedFileList = ({ files }: Props) => {
  const theme = useTheme()

  return (
    <Stack sx={{ pt: theme.spacing(1), color: theme.palette.error.main }}>
      {files.map(({ file }) => (
        <FileListText key={file.name}>{file.name}</FileListText>
      ))}
    </Stack>
  )
}
