import { useState, useContext, createContext, PropsWithChildren, useMemo, useCallback } from 'react'
import { Snackbar } from './Snackbar'
import {
  Horizontal,
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  SnackbarOrigin,
  TransitionDuration,
  Vertical,
} from './types'

export type ToastProps = {
  message: SnackbarMessage
  key: SnackbarKey
  duration: number
  severity: 'success' | 'info' | 'warning' | 'error'
  position: {
    vertical: Vertical
    horizontal: Horizontal
  }
  withCloseButton: boolean
  preventDuplicate: boolean
  transitionDuration: TransitionDuration | number
  disableAnimation: boolean
  visible: boolean
  width: number | string
}

export type SnackbarContextProps = {
  addToast: (message: SnackbarMessage, toast: OptionsObject) => SnackbarKey
  removeToast: (key: ToastProps['key']) => void
  dismissAll: () => void
}

type SnackbarContent = Partial<Record<`${Vertical}_${Horizontal}`, ToastProps[]>>

type Props = PropsWithChildren & {
  withCloseButton?: boolean
  disableAnimation?: boolean
  anchorOrigin?: SnackbarOrigin
  autoHideDuration?: number
  width?: number | string
}

const defaultPosition: {
  vertical: Vertical
  horizontal: Horizontal
} = {
  vertical: 'top',
  horizontal: 'center',
}

const SnackbarContext = createContext<SnackbarContextProps>({
  addToast: () => ``,
  removeToast: () => {},
  dismissAll: () => {},
})

const normalizeOptions = (message: SnackbarMessage, toastProps: OptionsObject, providerProps: Props): ToastProps => ({
  message,
  key: toastProps.key || toastProps.preventDuplicate ? JSON.stringify(message) : Date.now(),
  position: toastProps.anchorOrigin || providerProps.anchorOrigin || defaultPosition,
  duration: toastProps.persist ? 0 : toastProps.autoHideDuration || providerProps.autoHideDuration || 6000,
  severity: toastProps.variant || 'info',
  preventDuplicate: toastProps.preventDuplicate || false,
  withCloseButton: toastProps.withCloseButton || providerProps.withCloseButton || false,
  transitionDuration: toastProps.transitionDuration || 300,
  disableAnimation: providerProps.disableAnimation || false,
  width: providerProps.width || 320,
  visible: true,
})

export const SnackbarProvider = ({
  children,
  withCloseButton,
  anchorOrigin,
  autoHideDuration,
  disableAnimation,
  width,
}: Props) => {
  const [toastsPack, setToastsPack] = useState<SnackbarContent>({})

  const addToast = useCallback(
    (message: SnackbarMessage, toastOptions: OptionsObject) => {
      const toastNormalized = normalizeOptions(message, toastOptions, {
        withCloseButton,
        anchorOrigin,
        autoHideDuration,
        disableAnimation,
        width,
      })

      setToastsPack((currentToasts) => {
        const positionKey: `${Vertical}_${Horizontal}` = `${toastNormalized.position.vertical}_${toastNormalized.position.horizontal}`
        const toasts = currentToasts[positionKey] || []

        const duplicateToastMessage = toasts.find((toast) => toast.message === message)
        if (toastNormalized.preventDuplicate && duplicateToastMessage?.key) {
          return currentToasts
        }

        let rest = [...toasts]
        if (toasts.length >= 3) {
          rest = rest.map((elm, idx) => ({
            ...elm,
            visible: idx < 2,
          }))
        }

        return {
          ...currentToasts,
          [positionKey]: [toastNormalized, ...rest],
        }
      })

      return toastNormalized.key
    },
    [anchorOrigin, autoHideDuration, disableAnimation, withCloseButton, width]
  )

  const removeToast = useCallback((toastKey: SnackbarKey) => {
    setToastsPack((prev) =>
      Object.entries(prev).reduce(
        (cur, [key, value]) => ({
          ...cur,
          [key]: value.filter((toast) => toast.key !== toastKey),
        }),
        {}
      )
    )
  }, [])

  const dismissAll = useCallback(() => {
    setToastsPack({})
  }, [])

  const providerValues = useMemo(() => ({ addToast, removeToast, dismissAll }), [addToast, removeToast, dismissAll])

  return (
    <SnackbarContext.Provider value={providerValues}>
      {children}
      {Object.entries(toastsPack).map(([key, toasts]) => (
        <Snackbar key={key} toasts={toasts} />
      ))}
    </SnackbarContext.Provider>
  )
}

export const useSnackbarContext = () => useContext(SnackbarContext)
