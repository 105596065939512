import { Card, Stack, Typography } from '@mui/material'
import React from 'react'

export const NoForm = () => (
  <Card>
    <Stack spacing={2}>
      <Typography variant="h4">No form found</Typography>

      <Typography variant="body2">There was no booking form found for this artist.</Typography>
    </Stack>
  </Card>
)
