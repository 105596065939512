// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export const useEmulators = false

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBvA0y5RfYNxqzEVCu-wmhrBXo2dvNWgG8',
  authDomain: 'ink-maps-50ec3.firebaseapp.com',
  projectId: 'ink-maps-50ec3',
  storageBucket: 'ink-maps-50ec3.appspot.com',
  messagingSenderId: '271122184624',
  appId: '1:271122184624:web:af893425ad35afac573e75',
  measurementId: 'G-QPHVGT21F0',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = getFirestore(app)
export const storage = getStorage(app)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdKYmcqAAAAAMhZQ-2Rri6HQVvYk389VL0eCyBR'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
})

if (window.location.host.includes('localhost') && useEmulators) {
  connectStorageEmulator(storage, '127.0.0.1', 9199)
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
}
