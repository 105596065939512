import React, { ReactNode } from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import { FileListText } from 'components/upload/FileListText'
import { Delete } from '@mui/icons-material'

type Props = {
  title: ReactNode
  files: Array<File>
  multiple?: boolean
  onDelete: (file: File) => void
}

export const AcceptedFileList = ({ files, title, onDelete, multiple = false }: Props) => {
  return (
    <Stack alignItems="center">
      {(files.length === 0 || multiple) && <Typography variant="h5">{title}</Typography>}
      {files.map((file, idx) => (
        <FileListText key={`${idx}`}>
          {file.name}
          <IconButton
            sx={{ ml: 1 }}
            onClick={(e) => {
              onDelete(file)
              e.stopPropagation()
            }}
          >
            <Delete />
          </IconButton>
        </FileListText>
      ))}
    </Stack>
  )
}
