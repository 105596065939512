import React, { ReactNode } from 'react'
import { Typography } from '@mui/material'

type Props = {
  children: ReactNode
  compact?: boolean
}

export const FileListText = ({ children, compact = false }: Props) => {
  return (
    <Typography variant={'body1'} sx={{ ml: 1, mr: 1 }}>
      {children}
    </Typography>
  )
}
