import React, { ReactNode, useState } from 'react'
import { ErrorMessage, useField } from 'formik'
import Dropzone, { FileRejection, Accept } from 'react-dropzone'
import { Grid2 as Grid, Typography } from '@mui/material'
// eslint-disable-next-line no-restricted-imports -- Use sx props or styled components to create CSS
import { RejectedFileList } from 'components/upload/RejectedFileList'
import { AcceptedFileList } from 'components/upload/AcceptedFileList'
import { Upload } from '@mui/icons-material'
import { FormError } from 'components/questions/FormError'
import { gray } from 'theme/themePrimitives'

export const FileTypes = {
  CSV: { 'text/csv': ['.csv'] },
  PDF: { 'application/pdf': ['.pdf'] },
  TXT: { 'text/plain': ['.txt'] },
  ZIP: { 'application/zip': ['.zip'], 'application/x-zip-compressed': ['.zip'] },
  DOC: {
    'application/msword': ['.doc', '.docx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.doc', '.docx'],
  },
  XLS: {
    'application/vnd.ms-excel': ['.xls', '.xlsx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
  },
  IMAGES: { 'image/*': ['.jpg', '.jpeg', '.png', '.svg'] },
  JPEG_IMAGE: { 'image/jpeg': ['.jpg', '.jpeg'] },
  PNG_IMAGE: { 'image/png': ['.png'] },
  SVG_IMAGE: { 'image/svg+xml': ['.svg'] },
  JSON: { 'application/json': ['.json'] },
} as const

type Props = {
  name: string
  acceptedTypes?: Object
  onChange?: (files: File[]) => void
  onError?: (rejectedFiles: FileRejection[]) => void
  title: ReactNode
  description?: ReactNode
  multiple?: boolean
  showRejectedFiles?: boolean
  maxFileSize?: number
  maxFiles?: number
  hideIcon?: boolean
}

export const UploadInput = ({
  name,
  onError,
  onChange,
  acceptedTypes = {},
  title,
  description,
  multiple,
  showRejectedFiles,
  maxFileSize,
  maxFiles = 1,
  hideIcon = false,
}: Props) => {
  const [rejected, setRejected] = useState<FileRejection[]>([])

  const [field, , helper] = useField<File[]>(name)

  function isNonEmptyArray<A>(input: A[]): boolean {
    return Array.isArray(input) && !!input.length
  }

  const updateAccepted = (acceptedFiles: File[]) => {
    const newAcceptedFiles = (multiple ? field.value.concat(acceptedFiles) : acceptedFiles).slice(0, maxFiles)
    helper.setValue(newAcceptedFiles)
    if (onChange) {
      onChange(newAcceptedFiles)
    }
  }

  const updateRejected = (rejectedFiles: FileRejection[]) => {
    setRejected(rejectedFiles)

    if (isNonEmptyArray(rejectedFiles) && onError) {
      onError(rejectedFiles)
    }
  }

  const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    updateAccepted(acceptedFiles)
    updateRejected(rejectedFiles)
  }

  const handleRemove = (removeFile: File) => {
    const newAcceptedFiles = field.value.filter((file) => file !== removeFile)
    helper.setValue(newAcceptedFiles)
  }

  const limitProps = maxFileSize ? { maxSize: maxFileSize } : {}

  const isWindows = (): boolean => {
    const { platform } = navigator || {}
    if (typeof platform === 'string') {
      return platform.startsWith('Win')
    }

    return false
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const hasCSV = Object.values(acceptedTypes).some((el) => el === FileTypes.CSV['text/csv'])
  const accept: unknown = hasCSV && isWindows() ? { ...acceptedTypes, ...FileTypes.XLS } : acceptedTypes

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: gray[800],
        border: `2px dashed ${gray[500]}`,
        borderRadius: 3,
        padding: 2,
        cursor: 'pointer',
      }}
    >
      <Dropzone
        onDrop={onDrop}
        data-test="drop-zone"
        accept={accept as Accept}
        multiple={multiple}
        disabled={field.value.length >= maxFiles}
        maxFiles={maxFiles}
        {...limitProps}
      >
        {({ getRootProps, getInputProps }) => (
          <Grid
            container
            direction={'column'}
            justifyContent="center"
            alignItems="center"
            {...getRootProps({
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onKeyDown: (event: { preventDefault: () => any }) => event.preventDefault(), // this prevents a bug from being fired
            })}
          >
            {!hideIcon && (
              <Upload
                sx={{
                  color: gray[500],
                  cursor: 'pointer',
                  fontSize: 22,
                  height: 12 * 8,
                  width: 12 * 8,
                }}
              />
            )}
            <input {...getInputProps()} data-test={`file-upload-cloud-input-${name}`} />
            <AcceptedFileList files={field.value} title={title} multiple={multiple} onDelete={handleRemove} />
            {showRejectedFiles && <RejectedFileList files={rejected} />}
            <Typography
              sx={{
                mt: 3,
                mb: 3,
                ml: 2,
                mr: 2,
                color: gray[500],
              }}
            >
              {description}
            </Typography>
            <ErrorMessage name={field.name} component={FormError} />
          </Grid>
        )}
      </Dropzone>
    </Grid>
  )
}
