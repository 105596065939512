import { FormQuestion } from 'types/BookingForm'
import { TextInputField } from 'components/questions/TextInputField'
import { RadioGroupInput } from 'components/questions/RadioInput'
import { FileTypes, UploadInput } from 'components/questions/UploadInput'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'components/snackbar'
import { FileRejection } from 'react-dropzone'

export const getDefault = (question: FormQuestion) => {
  switch (question) {
    case FormQuestion.name:
    case FormQuestion.phone:
    case FormQuestion.email:
    case FormQuestion.description:
    case FormQuestion.size:
    case FormQuestion.maxdays:
    case FormQuestion.placement:
    case FormQuestion.misc:
      return ''
    case FormQuestion.coverup:
    case FormQuestion.before:
    case FormQuestion.multiday:
    case FormQuestion.beforeByMe:
      return ''
    case FormQuestion.placementImages:
    case FormQuestion.referencesImages:
      return []
  }
}

export const getQuestionText = (question: FormQuestion) => {
  switch (question) {
    case FormQuestion.name:
      return 'Full name'
    case FormQuestion.phone:
      return 'Phone number'
    case FormQuestion.email:
      return 'E-mail'
    case FormQuestion.description:
      return 'Description of your tattoo'
    case FormQuestion.size:
      return 'Approximate size'
    case FormQuestion.coverup:
      return 'Is this a coverup?'
    case FormQuestion.before:
      return 'Do you already have tattoos?'
    case FormQuestion.beforeByMe:
      return 'Do you already have tattoos done by me?'
    case FormQuestion.multiday:
      return 'Can you do multi day back to back sessions?'
    case FormQuestion.maxdays:
      return 'How many days can you endure back to back?'
    case FormQuestion.referencesImages:
      return 'Reference / inspiration images of your design'
    case FormQuestion.placement:
      return 'Placement of your tattoo'
    case FormQuestion.placementImages:
      return 'Placement images of your body part'
    case FormQuestion.misc:
      return 'Other questions / remarks'
  }
}

export const renderQuestion = (
  question: FormQuestion,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey
) => {
  const handleUploadError = (rejectedFiles: FileRejection[]) => {
    const errorString = rejectedFiles
      .map((file) => `${file.file.name}: ${file.errors.map((e) => e.message).join(', ')}`)
      .join('\n')

    enqueueSnackbar(`Error with uploading files: ${errorString}`, {
      variant: 'error',
    })
  }
  switch (question) {
    case FormQuestion.name:
    case FormQuestion.phone:
    case FormQuestion.email:
    case FormQuestion.size:
    case FormQuestion.placement:
    case FormQuestion.maxdays:
      return <TextInputField name={question} />
    case FormQuestion.misc:
    case FormQuestion.description:
      return <TextInputField name={question} multiline />
    case FormQuestion.coverup:
    case FormQuestion.before:
    case FormQuestion.beforeByMe:
    case FormQuestion.multiday:
      return <RadioGroupInput name={question} options={['Yes', 'No']} />
    case FormQuestion.referencesImages:
      return (
        <UploadInput
          name={question}
          title={'Upload your reference images here (max 3)'}
          multiple={true}
          onError={handleUploadError}
          maxFileSize={2 * 1024 * 1024}
          maxFiles={3}
          acceptedTypes={FileTypes.IMAGES}
        />
      )
    case FormQuestion.placementImages:
      return (
        <UploadInput
          name={question}
          title={'Upload your placement images here (max 3)'}
          multiple={true}
          onError={handleUploadError}
          maxFileSize={1024 * 1024}
          maxFiles={3}
          acceptedTypes={FileTypes.IMAGES}
        />
      )
  }
}

const emailAddressRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const validateSpecific = (q: FormQuestion, answer: unknown) => {
  switch (q) {
    case FormQuestion.email:
      return (answer as string).match(emailAddressRegex) ? {} : { [q as string]: 'Please enter a valid email address!' }
    case FormQuestion.maxdays:
      return (answer as string).match(/[0-9]/) ? {} : { [q as string]: 'Please enter a valid number between 0 to 9!' }
  }
  return {}
}

export const validateQuestions = (questions: FormQuestion[], answers: { [q: string]: unknown }) => {
  const isRequired = (q: FormQuestion) => {
    if (q === FormQuestion.misc) return false
    if (q === FormQuestion.placementImages) return false
    return true
  }
  const sameAsDefault = (q: FormQuestion) => JSON.stringify(answers[q]) === JSON.stringify(getDefault(q))

  const validateQuestion = (q: FormQuestion) => {
    if (!isRequired(q)) {
      return {}
    } else {
      if (sameAsDefault(q)) return { [q as string]: 'Required' }
    }

    return validateSpecific(q, answers[q])
  }

  return {
    ...questions.map((q) => validateQuestion(q)).reduce((acc, obj) => ({ ...acc, ...obj }), {}),
  }
}
