import { Card, Stack, Typography } from '@mui/material'
import React from 'react'

export const FormNotActive = () => (
  <Card>
    <Stack spacing={2}>
      <Typography variant="h4">Bookings closed</Typography>

      <Typography variant="body2">All bookings are closed at the moment!</Typography>
    </Stack>
  </Card>
)
