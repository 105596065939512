import { Route, Routes } from 'react-router-dom'

import { Page } from 'components/Page'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<Page />} path={`/`} />
      <Route element={<Page />} path={`/:artistId/*`} />
    </Routes>
  )
}
