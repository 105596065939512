import React, { PropsWithChildren } from 'react'
import { Typography } from '@mui/material'

type Props = PropsWithChildren<{}>

export const FormError = ({ children }: Props) => (
  <Typography
    variant="caption"
    sx={{
      color: 'error.main',
      marginTop: 1,
      '&:empty': {
        display: 'none',
      },
    }}
  >
    {children}
  </Typography>
)
