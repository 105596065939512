import React from 'react'
import { TextField } from '@mui/material'
import { useField } from 'formik'

type Props = {
  name: string
  multiline?: boolean
}

export const TextInputField = ({ name, multiline = false }: Props) => {
  const [field, meta, helper] = useField(name)

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        id={name}
        name={name}
        multiline={multiline}
        rows={multiline ? 3 : 1}
        value={field.value}
        onChange={(change) => helper.setValue(change.target.value)}
        onBlur={() => helper.setTouched(true)}
        error={!!meta.error}
        helperText={meta.touched && meta.error}
      />
    </>
  )
}
