import { useState, useEffect, useCallback } from 'react'
import { Alert } from '@mui/material'
import Slide from '@mui/material/Slide'
import { useSnackbarContext, ToastProps } from './SnackbarProvider'
import { Horizontal, Vertical } from './types'

type SnackbarToastProps = {
  toast: ToastProps
}

const getDirection = (position: { vertical: Vertical; horizontal: Horizontal }) => {
  if (position.horizontal === 'center') {
    return position.vertical === 'top' ? 'down' : 'up'
  }

  if (position.horizontal === 'left') {
    return 'right'
  }

  return 'left'
}

export const SnackbarToast = ({ toast }: SnackbarToastProps) => {
  const [open, setOpen] = useState(toast.visible)
  const { removeToast } = useSnackbarContext()

  const close = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    if (toast.duration !== 0) {
      setTimeout(() => {
        close()
      }, toast.duration || 6000)
    }
  }, [close, toast.duration])

  useEffect(() => {
    if (!toast.visible) {
      close()
    }
  }, [toast.visible, close])

  const handleAnimationExit = () => removeToast(toast.key)

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    close()
  }

  return (
    <Slide
      in={open}
      timeout={toast.disableAnimation ? 0 : toast.transitionDuration}
      onExited={handleAnimationExit}
      direction={getDirection(toast.position)}
    >
      <Alert
        className={toast.visible ? `SnackbarItem-message` : ``}
        key={toast.key}
        severity={toast.severity}
        onClose={toast.withCloseButton ? handleClose : undefined}
        onClick={handleClose}
        variant="filled"
        sx={{
          mb: 1,
          border: 'none',
          '& .MuiButtonBase-root': {
            color: 'inherit',
          },
        }}
      >
        {toast?.message}
      </Alert>
    </Slide>
  )
}
