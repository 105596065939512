import React, { useEffect, useState } from 'react'
import { Card, CircularProgress, Container, Link, Stack, Typography } from '@mui/material'
import { addDoc, updateDoc, collection, getDocs } from 'firebase/firestore'
import { logEvent } from 'firebase/analytics'
import { analytics, db, storage } from 'config/configuration'
import { BookingForm, FormQuestion } from 'types/BookingForm'
import { FormNotActive } from 'components/FormNotActive'
import { uploadBytes, ref } from 'firebase/storage'
import { NoForm } from 'components/NoForm'
import { Form } from 'components/Form'
import { FormikValues } from 'formik'
import { useSnackbar } from 'components/snackbar'
import { useParams } from 'react-router-dom'
import { generateID } from 'components/upload/id'
import { SuccessComponent } from 'components/SuccessComponent'

const getForm = async (artistId: string): Promise<BookingForm | undefined> => {
  const formsCollection = collection(db, `/artists/${artistId}/booking_form`)
  const formsSnapshot = await getDocs(formsCollection)
  const result = formsSnapshot.docs.map((doc) => doc.data())
  if (result.length > 0) return result[0] as BookingForm

  return undefined
}

export const Page = () => {
  const [form, setForm] = useState<BookingForm>()
  const { enqueueSnackbar } = useSnackbar()
  const { artistId } = useParams()
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    getForm(artistId as string)
      .then((result) => {
        setForm(result)
        setLoading(false)
      })
      .catch((error) => {
        logEvent(analytics, 'exception', { description: error.toString() })
      })
  }, [artistId])

  const uploadImage = async (file: File, question: string, bookingId: string): Promise<string> => {
    const buffer = await file.arrayBuffer()
    const fileExt = file.name.split('.').pop()
    const imageRef = `${generateID(12)}.${fileExt}`

    await uploadBytes(ref(storage, `/images/${artistId}/bookings/${bookingId}/${question}/${imageRef}`), buffer, {
      customMetadata: { key: '1f87045b-0ac4-4c85-8578-1da0ce9655b7' },
    })
    return imageRef
  }

  const uploadImages = async (files: File[], question: string, bookingId: string) => {
    const result = await Promise.all(files.map((file) => uploadImage(file, question, bookingId)))
    return result
  }

  const saveBooking = async (values: FormikValues): Promise<void> => {
    const currentForm = await getForm(artistId as string)
    if (!currentForm || !currentForm.is_active) {
      enqueueSnackbar('We apologize for the inconvenience, but the form closed while you were submitting!', {
        variant: 'warning',
      })
      return
    }

    try {
      const {
        [FormQuestion.placementImages]: placementImages,
        [FormQuestion.referencesImages]: referenceImages,
        ...other
      } = values

      const bookingCollection = collection(db, `/artists/${artistId}/bookings`)
      const ref = await addDoc(bookingCollection, {
        ...other,
        booking_status: 'unread',
        created: new Date(),
        artistId,
      })

      const referenceImagesRefs = await uploadImages(referenceImages, FormQuestion.referencesImages, ref.id)
      const placementImagesRefs = await uploadImages(placementImages, FormQuestion.placementImages, ref.id)

      await updateDoc(ref, {
        [FormQuestion.referencesImages]: referenceImagesRefs.join('|'),
        [FormQuestion.placementImages]: placementImagesRefs.join('|'),
      })

      setSuccess(true)
    } catch (e) {
      enqueueSnackbar(`Failed to submit your booking form. ${e}`, { variant: 'error' })
    }
  }

  return (
    <Container maxWidth="lg" sx={{ p: 1 }}>
      <Stack spacing={2}>
        {loading && (
          <Card>
            <Stack alignItems="center">
              <CircularProgress sx={{ color: 'white' }} />
            </Stack>
          </Card>
        )}
        {!success && !loading && (
          <>
            {!form && <NoForm />}
            {form && !form.is_active && <FormNotActive />}
            {form && form.is_active && <Form form={form} onSubmit={saveBooking} />}
          </>
        )}
        {success && !loading && <SuccessComponent artistName={form?.artist_name ?? 'your artist'} />}

        <Typography variant="caption">
          Booking form powered by&nbsp;
          <Link href="https://www.inkmapstattooapp.com/">Ink Maps: The Ultimate Tattoo App</Link>
        </Typography>
      </Stack>
    </Container>
  )
}
