import React from 'react'
import { Stack, Snackbar as MuiSnackbar, Collapse, Slide } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'
import { SnackbarToast } from './SnackbarToast'
import { ToastProps } from './SnackbarProvider'

type Props = {
  toasts: ToastProps[]
}

export const Snackbar = ({ toasts }: Props) => {
  const newToasts = [...toasts]

  if (!toasts || toasts.length === 0) {
    return null
  }

  if (toasts[0].position?.vertical === 'top') {
    newToasts.reverse()
  }

  return (
    <MuiSnackbar
      id="snackbar-container"
      open
      autoHideDuration={null}
      transitionDuration={0}
      TransitionComponent={Slide}
      anchorOrigin={toasts[0].position}
      sx={{
        mt: 'env(safe-area-inset-top)',
        mb: 'env(safe-area-inset-bottom)',
      }}
    >
      <Stack
        flexDirection="column"
        gap={1}
        sx={{
          px: 2.5,
          py: { xs: 1, sm: 0 },
        }}
      >
        <TransitionGroup enter={false}>
          {newToasts.map((toast) => (
            <Collapse key={toast.key} sx={{ backgroundColor: 'transparent' }}>
              <SnackbarToast toast={toast} />
            </Collapse>
          ))}
        </TransitionGroup>
      </Stack>
    </MuiSnackbar>
  )
}
