import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { getDownloadURL, ref } from 'firebase/storage'
import { storage } from 'config/configuration'
import { useParams } from 'react-router-dom'

type Props = {
  imageRef: string
}

export const StorageImage = ({ imageRef }: Props) => {
  const [url, setUrl] = useState<string>()
  const { artistId } = useParams()

  useEffect(() => {
    if (imageRef) {
      try {
        getDownloadURL(ref(storage, `/images/${artistId}/booking-form/${imageRef}`)).then(setUrl)
      } catch (e) {}
    }
  }, [artistId, imageRef])

  return url ? (
    <Box
      component="img"
      sx={{
        maxHeight: { lg: 297, md: 233, xs: 167 },
        maxWidth: { lg: 450, md: 350, xs: 250 },
      }}
      alt="Tattoo Shop Logo"
      src={url}
    />
  ) : (
    <Box />
  )
}
