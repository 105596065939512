import React from 'react'
import { Card, Fab, Stack, Typography } from '@mui/material'
import { Email } from '@mui/icons-material'
import { BookingForm, FormQuestion } from 'types/BookingForm'
import { Formik, FormikValues } from 'formik'
import { Question } from 'components/questions/Question'
import { getDefault, validateQuestions } from 'components/questions/question-switch'
import { StorageImage } from 'components/StorageImage'
import { red } from 'theme/themePrimitives'

type Props = {
  form: BookingForm
  onSubmit: (values: FormikValues) => Promise<void>
}

export const Form = ({ form, onSubmit }: Props) => {
  const questions = form.questions
    .split('|')
    .map((s) => Object.values(FormQuestion).find((k) => k === s))
    .filter((k) => !!k)
    .map((k) => k!)

  const initialValues = {
    ...questions.map((q) => ({ [q as string]: getDefault(q) })).reduce((acc, obj) => ({ ...acc, ...obj }), {}),
  }

  const handleSubmit = (values: FormikValues) => onSubmit(values)

  return (
    <Card>
      <Formik
        initialValues={initialValues}
        validate={(values) => validateQuestions(questions, values)}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ submitForm, isValid, isSubmitting }) => (
          <>
            <Stack spacing={2} alignItems="center">
              <Typography variant="h4">{form.artist_name}</Typography>
              <Typography variant="h5">Booking Form</Typography>

              <StorageImage imageRef={form.image_ref} />
            </Stack>
            <Stack spacing={2}>
              {questions.map((q) => (
                <Question key={q} question={q} />
              ))}

              {!isValid && <Typography sx={{ color: red[400] }}>Invalid values on the form!</Typography>}

              <Stack direction="row" alignSelf="self-end">
                <Fab variant="extended" onClick={() => submitForm()} disabled={isSubmitting}>
                  <Email sx={{ mr: 1 }} />
                  Book
                </Fab>
              </Stack>
            </Stack>
          </>
        )}
      </Formik>
    </Card>
  )
}
