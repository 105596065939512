import React from 'react'
import './App.css'
import { BrowserRouter as Router } from 'react-router-dom'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import getBlogTheme from './theme/getBlogTheme'

import { SnackbarProvider } from 'components/snackbar'
import { AppRoutes } from 'AppRoutes'

function App() {
  const blogTheme = createTheme(getBlogTheme('dark'))

  return (
    <SnackbarProvider>
      <ThemeProvider theme={blogTheme}>
        <CssBaseline enableColorScheme />
        <Router>
          <AppRoutes />
        </Router>
      </ThemeProvider>
    </SnackbarProvider>
  )
}

export default App
