export type BookingForm = {
  is_active: boolean
  questions: string
  artist_name: string
  image_ref: string
}

export enum FormQuestion {
  name = 'name',
  phone = 'phone',
  email = 'email',
  description = 'description',
  size = 'size',
  coverup = 'coverup',
  before = 'before',
  beforeByMe = 'beforeByMe',
  multiday = 'multiday',
  maxdays = 'maxdays',
  referencesImages = 'referencesImages',
  placement = 'placement',
  placementImages = 'placementImages',
  misc = 'misc',
}
