import React, { useRef } from 'react'
import { Box, FormControlLabel, Radio, RadioGroup, RadioGroupProps, useTheme } from '@mui/material'
import { ErrorMessage, FieldInputProps, useField } from 'formik'

type Props = Omit<RadioGroupProps, keyof FieldInputProps<string>> & {
  name: string
  options: string[]
}

export const RadioGroupInput: React.FC<Props> = ({ name, options, ...props }) => {
  const [field, meta] = useField<string>(name)
  const inputRef = useRef<HTMLInputElement>(null)

  const theme = useTheme()

  return (
    <>
      <RadioGroup {...props} name={name} onChange={field.onChange} value={field.value} ref={inputRef}>
        {options.map((o) => (
          <FormControlLabel
            key={`radio-${name}-${o}`}
            value={o}
            control={<Radio color="primary" />}
            label={o}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
      {meta.error && (
        <Box sx={{ color: theme.palette.error.main, fontSize: '0.75rem' }}>
          <ErrorMessage name={name} />
        </Box>
      )}
    </>
  )
}
