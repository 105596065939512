import { useCallback } from 'react'
import { EnqueueSnackbarFn, SnackbarKey, OptionsObject } from './types'
import { useSnackbarContext } from './SnackbarProvider'

type ReturnValue = {
  enqueueSnackbar: EnqueueSnackbarFn
  closeSnackbar: (key: SnackbarKey) => void
}

export const useSnackbar = (snackbarOptions?: OptionsObject): ReturnValue => {
  const { addToast, removeToast, dismissAll } = useSnackbarContext()

  const enqueueSnackbar: EnqueueSnackbarFn = useCallback(
    (message, options = snackbarOptions ?? {}) => addToast(message, options),
    [addToast, snackbarOptions]
  )

  const closeSnackbar = useCallback(
    (key: SnackbarKey) => (key ? removeToast(key) : dismissAll()),
    [removeToast, dismissAll]
  )

  return {
    enqueueSnackbar,
    closeSnackbar,
  }
}
