import { Stack, Typography } from '@mui/material'
import { FormQuestion } from 'types/BookingForm'
import { getQuestionText, renderQuestion } from 'components/questions/question-switch'
import { useSnackbar } from 'components/snackbar'

type Props = {
  question: FormQuestion
}

export const Question = ({ question }: Props) => {
  const { enqueueSnackbar } = useSnackbar()

  return (
    <>
      <Stack>
        <Typography sx={{ mb: 1 }}>{getQuestionText(question)}</Typography>
        {renderQuestion(question, enqueueSnackbar)}
      </Stack>
    </>
  )
}
